"use client";

import { useContext, useReducer, createContext } from "react";

const CurrencyStateContext = createContext();
const CurrencyDispatchContext = createContext();
function currencyReducer(state, action) {
  switch (action.type) {
    case "set": {
      return { currency: action.currency };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function CurrencyProvider({ children }) {
  const [state, dispatch] = useReducer(currencyReducer, {
    currency: "CAD",
    isCurrencyChanging: false,
  });
  return (
    <CurrencyStateContext.Provider value={state}>
      <CurrencyDispatchContext.Provider value={dispatch}>
        {children}
      </CurrencyDispatchContext.Provider>
    </CurrencyStateContext.Provider>
  );
}
function useCurrencyState() {
  const context = useContext(CurrencyStateContext);
  if (context === undefined) {
    throw new Error("useCurrencyState must be used within a CurrencyProvider");
  }
  return context;
}
function useCurrencyDispatch() {
  const context = useContext(CurrencyDispatchContext);
  if (context === undefined) {
    throw new Error("useCurrencyDispatch must be used within a CurrencyProvider");
  }
  return context;
}
export { CurrencyProvider, useCurrencyState, useCurrencyDispatch };
