import { useTranslation } from "../../../app/i18n/client";
import PropTypes from "prop-types";
import styles from "./styles/lineitem.module.css";
import { resolveFinalSale } from "./lib/resolve-final-sale";
import { resolveDiscount } from "./lib/resolve-discount";

export function FinalSale({ finalSale, discount }) {
  const { t } = useTranslation("cart");
  const finalSaleText = resolveFinalSale(finalSale, t);
  const discountText = resolveDiscount(discount, t);
  if (finalSaleText || discountText) {
    return (
      <div className={styles.savings}>
        {finalSaleText}
        {discountText}
      </div>
    );
  }
  return null;
}

FinalSale.propTypes = {
  finalSale: PropTypes.bool.isRequired,
  discount: PropTypes.string,
};

FinalSale.defaultProps = {
  discount: null,
};
