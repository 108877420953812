import { useRouter, usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export const useLanguage = () => {
  const [language, setLanguage] = useState(null);
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const lng = pathname.split("/")[1];
    setLanguage(lng);
  }, [router]);

  return language;
};
