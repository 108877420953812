import { useTranslation } from "../../app/i18n/client";
import PropTypes from "prop-types";
import { debounce } from "debounce";
import { ProductCardFooter } from "./ProductCardFooter";
import { resolveSpecialText } from "./lib/resolve-special-text";
import { resolveSale } from "./lib/resolve-sale";
import { resolvePrice } from "./lib/resolve-price";
import { useMobileDetect } from "@hooks/index";
import { googleAnalytics } from "@lib/gtag";
import { useCurrencyState } from "../../hooks";
import { useQuickAdd } from "./hooks/useQuickAdd";
import { ImageSection } from "./ImageSection";
import styles from "./ProductCard.module.css";
import { useEffect, useState } from "react";
import { trackEvent } from "@lib/track-event";
import { AlgoliaEventsApi } from "@lib/algoila-events-api";

const ProductRecommendationWrapper = ({ product, listPosition, children }) => {
  const productCardPartOfRecommendationsFeed = product.recommendation ? true : false;

  if (productCardPartOfRecommendationsFeed) {
    return (
      <div
        className={styles.container}
        tabIndex={listPosition}
        data-recommendation-id={product.recommendation.recommendation_id}
        data-depict_product_id={product.recommendation.product_id}
        data-depict_variant_ids={product.recommendation.variants_ids}
        data-type={product.recommendation.type}
      >
        {children}
      </div>
    );
  }

  return (
    <div className={styles.container} tabIndex={listPosition}>
      {children}
    </div>
  );
};

const Product = ({
  product,
  collection,
  listPosition,
  handleCloseDrawer,
  colour,
  openBlank = false,
  fromSearchView = false,
}) => {
  const { currency } = useCurrencyState();
  const currentDevice = useMobileDetect();
  const { t } = useTranslation("productcard");

  const {
    showQuickAdd,
    toggleQuickAdd,
    selectedColour,
    setSelectedColour,
    selectedSize,
    setSelectedSize,
    cartIsLoading,
    query,
  } = useQuickAdd(product, colour);

  // if (
  //   !query ||
  //   !product ||
  //   !product.images ||
  //   !product.images.length > 0 ||
  //   product.sanity?.deadstock
  // ) {
  //   return null;
  // }

  const selectedProduct = product.sanity?.shopifyProducts.find((p) => p.id === selectedColour?.id);

  if (!selectedProduct) {
    return null;
  }
  const isMobile = currentDevice.isMobile();

  const { price, formattedPrice } = resolvePrice(
    selectedProduct.variants ? selectedProduct : product,
    currency
  );
  const salePrice = resolveSale(price, currency);
  const specialText = resolveSpecialText(selectedProduct, salePrice !== null, t, isMobile);

  const selectItem = () => {
    googleAnalytics("event", "select_item", {
      ecommerce: {
        items: [
          {
            item_id: product?.id,
            item_name: product?.title,
            index: listPosition,
            item_list_name: collection,
            item_category: product?.productType,
            item_variant: product?.options[0]?.values[0],
            price: parseInt(price?.price?.amount || "0", 10),
            currency,
            quantity: "1",
          },
        ],
      },
    });

    if (fromSearchView) {
      AlgoliaEventsApi({
        events: [
          {
            eventType: "click",
            eventName: "Search Click",
            userToken: "nouser",
            index: "production_kotn_products",
            queryID: fromSearchView,
            timestamp: new Date().getTime(),
            queryID: localStorage.getItem("algolia-query-id"),
            objectIDs: [product?.id],
            positions: [listPosition + 1],
          },
        ],
      });
    }
    if (handleCloseDrawer) handleCloseDrawer();
  };

  return (
    <ProductRecommendationWrapper product={product}>
      <ImageSection
        listPosition={listPosition}
        selectItem={selectItem}
        query={query}
        specialText={specialText}
        collection={collection}
        openBlank={openBlank}
        isMobile={isMobile}
        selectedProduct={selectedProduct.images ? selectedProduct : product}
      />

      <ProductCardFooter
        product={product}
        salePrice={salePrice?.replace(/[A-Z]/g, "")}
        price={formattedPrice?.replace(/[A-Z]/g, "")}
        showQuickAdd={showQuickAdd}
        cartIsLoading={cartIsLoading}
        toggleQuickAdd={toggleQuickAdd}
        selectedColour={selectedColour}
        setSelectedColour={setSelectedColour}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        selectedProduct={selectedProduct}
        listPosition={listPosition}
        selectItem={selectItem}
        openBlank={openBlank}
        query={query}
        isMobile={isMobile}
      />
    </ProductRecommendationWrapper>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    availableForSale: PropTypes.bool.isRequired,
    handle: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.array,
    options: PropTypes.array,
    sanity: PropTypes.object,
    tags: PropTypes.array,
    type: PropTypes.string,
    variants: PropTypes.array.isRequired,
    url: PropTypes.object,
    collection: PropTypes.string,
  }).isRequired,
  listPosition: PropTypes.number,
};

Product.defaultProps = {
  listPosition: 0,
};

export default Product;
