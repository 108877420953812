import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../app/i18n/client";
import PropTypes from "prop-types";
import { Checkbox } from "@components/Checkbox";
import { Button } from "@components/Button";
import { resolveFooterNotice } from "./lib/resolve-footer-notice";
import { toteIsInCart } from "./lib/tote-is-in-cart";
import styles from "./styles/footer.module.css";
import { useCheckoutDispatch, useCheckoutState } from "@hooks/useCheckout";
import { TOTE } from ".";

function CartPackagingOptOut() {
  const { checkout } = useCheckoutState();
  const [checked, setChecked] = useState(false);
  const [toteCheckoutItemId, setToteCheckoutItemId] = useState();
  const { addCartItem, updateCartItem } = useCheckoutDispatch();

  useEffect(() => {
    if (!checked && !toteIsInCart(TOTE, checkout)) {
      addCartItem(TOTE, 1);
    }

    if (checked && toteIsInCart(TOTE, checkout)) {
      updateCartItem({
        ...TOTE,
        itemId: toteCheckoutItemId,
        quantity: 0,
      });
    }
  }, [checked]);

  useEffect(() => {
    if (!checked) {
      checkout.lineItems.edges.forEach(({ node }) => {
        if (node.title === TOTE.title) {
          setToteCheckoutItemId(node.id);
        }
      });
    }
  }, [checkout]);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className={styles.row}>
      <div className={styles.packing}>
        <Checkbox checked={checked} onChange={handleChange} />
      </div>
      <div>
        <p>
          We pack all of our orders with a signature Kotn tote. Click here to opt-out of the tote
          and reduce your packaging.
        </p>
      </div>
    </div>
  );
}
export function CartDrawerFooter({ handleSubmit, loading, location, total }) {
  const { t } = useTranslation("cart");
  const inNorthAmerica = resolveFooterNotice(location, total, t);
  return (
    <footer className={styles.footer}>
      {/* <CartPackagingOptOut /> */}
      {inNorthAmerica}
      <Button
        handleClick={handleSubmit}
        isDisabled={loading}
        description="checkout"
        descriptionText="Click to go to the checkout page"
      >
        {loading ? t("checkout-button-loading") : t("checkout-button")}
      </Button>
    </footer>
  );
}

CartDrawerFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};
