"use client";

import { logError } from "@lib/logger";
import { viewPage } from "./view-page";
import { search } from "./search";
import { viewCollection } from "./view-collection";
import { viewItemList } from "./view-item-list";
import { customizeProduct } from "./customize-product";
import { viewItem } from "./view-item";
import { addToCart } from "./add-to-cart";
import { beginCheckout } from "./begin-checkout";
import { generateLead } from "./generate-lead";
import { identify } from "./identify";
import { viewEmailCapture, submitEmailCapture } from "./email-capture";
import { loginCapture } from "./login-capture";
import { selectContent } from "./select-content";

export function trackEvent(event, data) {
  try {
    switch (event) {
      case "view_page": {
        viewPage(data);
        return;
      }

      case "search": {
        search(data);
        return;
      }

      case "view_collection": {
        viewCollection(data);
        return;
      }
      case "view_item_list": {
        viewItemList(data);
        return;
      }

      case "customize_product": {
        customizeProduct(data);
        return;
      }

      case "view_item": {
        viewItem(data);
        return;
      }

      case "add_to_cart": {
        addToCart(data);
        return;
      }

      case "begin_checkout": {
        beginCheckout(data);
        return;
      }

      case "generate_lead": {
        generateLead(data);
        return;
      }
      case "identify": {
        identify(data);
        return;
      }
      case "view_email_capture": {
        viewEmailCapture(data);
        return;
      }
      case "submit_email_capture": {
        submitEmailCapture(data);
        return;
      }
      case "login": {
        loginCapture(data);
        return;
      }
      case "select_content": {
        selectContent(data);
        return;
      }
      default:
        return;
    }
  } catch (err) {
    logError(err);
    // add client logging error
  }
}
