import { useTranslation } from "../../../app/i18n/client";
import PropTypes from "prop-types";
import styles from "./styles/price.module.css";
import stylesList from "./styles/lineitem.module.css";

export function Price({ price, compareAtPrice }) {
  const { t } = useTranslation("cart");
  return (
    <li className={stylesList.listItem}>
      <span>{t("price-label")}:</span>
      <div className={styles.container}>
        <span>{price}</span>
        {compareAtPrice && <s>{compareAtPrice.replace(/[A-Z]/g, "")}</s>}
      </div>
    </li>
  );
}

Price.propTypes = {
  price: PropTypes.string.isRequired,
  compareAtPrice: PropTypes.string,
};

Price.defaultProps = {
  compareAtPrice: null,
};
