import { useTranslation } from "../../../app/i18n/client";
import PropTypes from "prop-types";
import styles from "./styles/quantity.module.css";
import stylesList from "./styles/lineitem.module.css";

export function Quantity({ value, max, disabled, onChange, onIncrease, onBlur, hide = false }) {
  const { t } = useTranslation("cart");
  return (
    <li className={stylesList.listItem}>
      <span>{t("quantity-label")}:</span>
      <div>
        {!hide && (
          <button
            type="button"
            onClick={() => onIncrease(-1)}
            disabled={disabled}
            className={styles.button}
          >
            -
          </button>
        )}
        <input
          type="number"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          min="0"
          max={max}
          step="1"
          className={styles.input}
        />
        {!hide && (
          <button
            type="button"
            disabled={disabled || Number(value) >= max}
            onClick={() => onIncrease(1)}
            className={styles.button}
          >
            +
          </button>
        )}
      </div>
    </li>
  );
}

Quantity.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
