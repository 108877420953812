"use client";

import { useEffect, useState } from "react";

const getMobileDetect = (userAgent = ["userAgent"], windowIsMobile) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () =>
    windowIsMobile || Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};
export const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 560) {
      setIsMobile(true);
      return;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 560) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  return getMobileDetect(userAgent, isMobile);
};
