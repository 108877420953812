"use client";

import { useState, useEffect } from "react";

const useForm = (initialValues, callback, validate) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState("");

  const normalizePhoneNumber = (value) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validate(values));

    setIsSubmitting(true);
    setServerError("");
  };

  const handleChange = (event) => {
    event.persist();
    if (event.target.type === "checkbox") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.checked,
      }));
    } else if (event.target.type === "tel") {
      setValues((values) => ({
        ...values,
        [event.target.name]: normalizePhoneNumber(event.target.value),
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    setIsSubmitting,
    isSubmitting,
    setServerError,
    serverError,
  };
};

export default useForm;
