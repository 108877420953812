import { useQuery } from "react-query";
import * as Sentry from "@sentry/node";

const getKlaviyoPersonId = async (email) => {
  try {
    const res = await fetch("/api/getKlaviyoUserId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const result = await res.json();

    return result.body.id;
  } catch (error) {
    Sentry.captureException(error);
    return {
      statusCode: 500,
      body: String(error),
    };
  }
};

const getKlaviyoUser = async (email) => {
  const id = await getKlaviyoPersonId(email);

  try {
    const res = await fetch("/api/getKlaviyoProfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ id }),
    });

    const result = await res.json();

    return result.body;
  } catch (error) {
    Sentry.captureException(error);
    return {
      statusCode: 500,
      body: String(error),
    };
  }
};

export function useKlaviyoInfo(email) {
  return useQuery(["getKlaviyoInfo", email], () => getKlaviyoUser(email), {
    enabled: !!email,
    refetchOnWindowFocus: false,
  });
}
