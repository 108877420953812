import { useState } from "react";
import { useTranslation } from "../../../app/i18n/client";
import { Spinner } from "@components/Spinner";
import { useRecommendations } from "./hooks/useRecommendations";
import { Product } from "@components/ProductCard";
import styles from "./styles/recommendations.module.css";

const ProductRow = ({ products, handleCloseDrawer }) => {
  return (
    <div className={styles.row}>
      {products?.map((product, index) => (
        <Product
          product={product}
          listPosition={index}
          key={`${product?.id}-${index}`}
          handleCloseDrawer={handleCloseDrawer}
        />
      ))}
    </div>
  );
};

export function Recommendations({ variant_ids, type, isInCart, emptyState, handleCloseDrawer }) {
  const { t } = useTranslation("recommendations");
  const [isHidden, setIsHidden] = useState(true);
  const { isError, isLoading, recommendations } = useRecommendations(variant_ids, type);

  const YOU_MAY_ALSO_LIKE = t("you-may-also-like");
  const POPULAR_PRODUCTS = t("popular-products");
  const VIEW_MORE = t("view-more");
  const VIEW_LESS = t("view-less");

  if (!recommendations || isError) return <div></div>;

  if (recommendations.length === 0) return <div></div>;

  const noDuplicateProducts = recommendations.filter(
    (product, index) =>
      recommendations.findIndex((_product) => {
        return _product.recommendation.product_id === product.recommendation.product_id;
      }) === index
  );

  const onlyAvailableProducts = noDuplicateProducts.filter((product) => product.sanity);

  const firstFourProducts = onlyAvailableProducts.slice(0, 4);
  const lastFourProducts = onlyAvailableProducts.slice(-4);

  // for cart we take the first product in case we want to push a promo, and randomize the next 3
  const firstProduct = onlyAvailableProducts.shift();
  const randomThreeProducts = onlyAvailableProducts
    .sort(() => Math.random() - Math.random())
    .slice(0, 3);
  const cartProducts = [firstProduct].concat(randomThreeProducts);

  const headerStyles = `
    ${isInCart ? styles.cartHeader : styles.header}
  `;

  if (isLoading) {
    return (
      <section className={styles.loading}>
        <Spinner />
      </section>
    );
  }

  const ShowMoreButton = () => {
    return (
      <button
        className={styles.button}
        onClick={() => {
          setIsHidden(!isHidden);
        }}
      >
        {isHidden ? VIEW_MORE : VIEW_LESS}
      </button>
    );
  };

  if (isInCart) {
    return (
      <section>
        {emptyState && <h2 className={headerStyles}>{POPULAR_PRODUCTS}</h2>}
        {!emptyState && <h2 className={headerStyles}>{YOU_MAY_ALSO_LIKE}</h2>}
        <ProductRow products={cartProducts} handleCloseDrawer={handleCloseDrawer} />
      </section>
    );
  }

  return (
    <section>
      <h2 className={headerStyles}>{YOU_MAY_ALSO_LIKE}</h2>
      <ProductRow products={firstFourProducts} />
      {!isHidden && <ProductRow products={lastFourProducts} />}
      <ShowMoreButton />
    </section>
  );
}
