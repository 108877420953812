"use client";

import { useEffect, useState } from "react";
import { isBefore } from "date-fns";

export function useBlackFriday(date) {
  const [isBlackFriday, setIsBlackFriday] = useState(false);

  useEffect(() => {
    setIsBlackFriday(isBefore(Date.now(), date));
  }, []);

  return isBlackFriday;
}
