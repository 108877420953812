"use client";

import { useEffect, useReducer, useContext, createContext } from "react";
import { useSession } from "next-auth/react";
import { logError } from "@lib/logger";

const MetafieldsStateContext = createContext();
const MetafieldsDispatchContext = createContext();

export const METAFIELDS_ACTIONS = {
  UPDATE: "@metafields/UPDATE",
};

const initialMetafieldsState = {
  customer_id: null,
  metafield: null,
};

function metafieldsReducer(state = initialMetafieldsState, action) {
  switch (action.type) {
    case METAFIELDS_ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}
// Todo handle throwing errors and displaying to user a message as a result of fetch failing
export function useCustomerMetafields() {
  const { data: session, status } = useSession();
  const state = useContext(MetafieldsStateContext);
  const dispatch = useContext(MetafieldsDispatchContext);

  const update = (payload) => dispatch({ type: METAFIELDS_ACTIONS.UPDATE, payload });

  useEffect(() => {
    async function setState() {
      const customer_id = await fetchCustomer();
      if (customer_id) {
        const metafield = await fetchMetafield(customer_id);
        update({ customer_id, metafield });
      }
    }

    if (session && status !== "loading" && !state.customer_id) {
      setState();
    }
  }, [status]);

  async function fetchCustomer() {
    try {
      const response = await fetch("/api/customer", {
        body: session.user.email,
        method: "POST",
        headers: { Accept: "application/json" },
      });
      const result = await response.json();

      if (result.error) {
        return null;
      }

      return result.customer[0].id;
    } catch (error) {
      logError(error);
      return null;
    }
  }

  async function fetchMetafield(customer_id) {
    try {
      const response = await fetch(`/api/customer/${customer_id}`, {
        method: "GET",
        headers: { Accept: "application/json" },
      });

      const result = await response.json();

      return result.data.find((meta) => meta.key === "checkout_id");
    } catch (error) {
      logError(error);
      return null;
    }
  }

  async function addMetafield(checkout_id) {
    if (!state.customer_id) {
      return null;
    }
    try {
      const response = await fetch(`/api/customer/${state.customer_id}`, {
        body: checkout_id,
        method: "POST",
        headers: { Accept: "application/json" },
      });
      const result = await response.json();
      update({ metafield: result.data });
      return result.data;
    } catch (error) {
      logError(error);
      return null;
    }
  }

  async function updateMetafield(checkout_id) {
    if (!state.customer_id) {
      return null;
    }
    try {
      const response = await fetch(`/api/customer/${state.customer_id}`, {
        body: JSON.stringify({
          metafield_id: state.metafield.id,
          metafield_value: checkout_id,
        }),
        method: "PUT",
        headers: { Accept: "application/json" },
      });
      const result = await response.json();
      update({ metafield: result.data });
      return result.data;
    } catch (error) {
      logError(error);
      return null;
    }
  }

  async function deleteMetafield() {
    try {
      const response = await fetch(`/api/customer/${state.customer_id}`, {
        body: state.metafield.id,
        method: "DELETE",
        headers: { Accept: "application/json" },
      });

      const result = await response.json();
      return result.data;
    } catch (error) {
      logError(error);
      return null;
    }
  }

  return {
    state,
    updateMetafield,
    addMetafield,
    deleteMetafield,
  };
}

export function MetafieldsProvider({ children }) {
  const [state, dispatch] = useReducer(metafieldsReducer, initialMetafieldsState);

  return (
    <MetafieldsStateContext.Provider value={state}>
      <MetafieldsDispatchContext.Provider value={dispatch}>
        {children}
      </MetafieldsDispatchContext.Provider>
    </MetafieldsStateContext.Provider>
  );
}
