import { useEffect, useState } from "react";
import Link from "next/link";
import { useTranslation } from "../../../app/i18n/client";
import PropTypes from "prop-types";
import * as currencyService from "@services/format/currency";
import styles from "./styles/empty.module.css";

export function CartDrawerEmpty({ location }) {
  const { t } = useTranslation("cart");
  const [inNorthAmerica, setInNorthAmerica] = useState(null);

  useEffect(() => {
    if (location && (location.country == "CA" || location.country == "US")) {
      setInNorthAmerica(` ${t("shipping-message")} ${currencyService.formatPrice(75)}+`);
    }
  }, [location]);

  return (
    <div className={styles.container}>
      <p>
        {t("empty-cart-message")}
        {inNorthAmerica}.
      </p>
      <p>
        <Link href="/collections/womens" passhref prefetch={false} legacyBehavior>
          <a>{t("shop-women")} &rarr;</a>
        </Link>
      </p>
      <p>
        <Link href="/collections/mens" passhref prefetch={false} legacyBehavior>
          <a>{t("shop-men")} &rarr;</a>
        </Link>
      </p>
      <p>
        <Link href="/collections/home" passhref prefetch={false} legacyBehavior>
          <a>{t("shop-home")} &rarr;</a>
        </Link>
      </p>
    </div>
  );
}

CartDrawerEmpty.propTypes = { location: PropTypes.object.isRequired };
