"use client";

import { useContext, useReducer, useEffect, createContext } from "react";
import { useRouter } from "next/navigation";
import { handleLoopReturnsParam } from "@lib/handle-loopReturns-params";

const KlaviyoStateContext = createContext();
const KlaviyoDispatchContext = createContext();

function klaviyoReducer(state, action) {
  switch (action.type) {
    case "setKlaviyo": {
      return { ...state, klaviyo: action.klaviyo };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function KlaviyoProvider({ children }) {
  const router = useRouter();
  const [state, dispatch] = useReducer(klaviyoReducer, {
    klaviyo: false,
  });

  useEffect(() => {
    handleLoopReturnsParam();
    const handleRouteChangeComplete = () => {
      document.body.style.overflow = "unset";
    };
    router?.events?.on("routeChangeComplete", handleRouteChangeComplete);

    const location = router.basePath === "" ? "http://localhost:3000" : window.location.href;

    const handle = location.match(/[^/]+(?=\/$|$)/g);
    let utmSource = null;
    if (handle && handle[0].indexOf("?") !== -1) {
      const url = new URL(location);
      utmSource = url.searchParams.get("utm_source");
    }

    if (utmSource && utmSource.toLowerCase() === "email") {
      dispatch({ type: "setKlaviyo", klaviyo: true });
    }

    return () => {
      router?.events?.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router]);

  return (
    <KlaviyoStateContext.Provider value={state}>
      <KlaviyoDispatchContext.Provider value={dispatch}>{children}</KlaviyoDispatchContext.Provider>
    </KlaviyoStateContext.Provider>
  );
}

function useKlaviyoState() {
  const context = useContext(KlaviyoStateContext);
  if (context === undefined) {
    throw new Error("useKlaviyoState must be used within a KlaviyoProvider");
  }
  return context;
}

function useKlaviyoDispatch() {
  const context = useContext(KlaviyoDispatchContext);
  if (context === undefined) {
    throw new Error("useKlaviyoDispatch must be used within a KlaviyoProvider");
  }
  return context;
}

export { KlaviyoProvider, useKlaviyoState, useKlaviyoDispatch };
